body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #131313;
}


.btn-primary {
  color: #fff;
  background-color: #97cdc7 !important;
  border-color: #97cdc7 !important;
}

.btn-primary:hover, .btn-primary:visited, .btn-primary:focus, .btn-primary:disabled {
  color: #fff;
  background-color: #663300;
  border-color: #663300;
}


a:hover, .nav-link:hover, .text-muted, .social {
  color: #663300 !important;
  font-family: Arial, Helvetica, sans-serif;
}

a, a:visited, a:focus, a:disabled, .nav-link {
  color: #97cdc7 !important;
  font-family: Arial, Helvetica, sans-serif;
}

.nav-link.active {
  color: #663300 !important;
  font-weight: bold;
  /* text-decoration: underline !important; */
}

.site-nav-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: auto;
  min-height: calc(370vh);
}

.site-nav-header nav, #review-tabs {
  position: relative;
  z-index: 1;
}

.navigation {
  background-color: #fff;
  margin-top: 0px;
  box-shadow: 2px 0px 30px 0px rgb(136 136 136 / 26%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.s;
  border-top: 2px solid #dee2e6 !important;
  border-bottom: 2px solid #dee2e6 !important;
}

.current-section {
  text-transform: uppercase;
  color: #663300;
  font-weight: bold;
  font-size: medium;
  display: none;
}

.parent-logo {
  display: block;
}

.parent-logo-small {
  display: none;
}

#page-nav {
  position: fixed;
  /* position: -webkit-sticky; */
  top: 0;
  z-index: 2;
  width: 100%;
}

footer .text-muted, .social {
  color: #131313 !important;
}

footer {
  background-color: #f6f6f6;
  z-index: 1;
  position: relative;
}

.site-content {
  height: 100%;
  margin: 0;
}

.site-content .page-section {
  margin-top: 125px;
  padding-bottom: 136px;
  z-index: 1;
  position: relative;
}

.site-content .alert-section {
  margin: auto;
  width: 750px;
  /* margin-top: 125px; */
}

.alert-section + .top-section {
  margin-top: 0px !important;
}

.site-content .top-section, .site-content .micro-section, .site-content .micro-section-grey {
  padding-top: 100px;
  padding-bottom: 100px;
  border-bottom: #cccccc solid 2px;
}

#home {
  padding-top: 125px;
}

.site-content .top-section {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  /* margin-top: 125px; */
  margin-bottom: 0;
  padding-top: 20px !important;
  padding-bottom: 125px !important;
  background-image: url(../images/art-back-bg@2x.png);
  background-position: center 0px;
  background-size: 1198px;
  background-repeat: no-repeat;
}

.site-content .top-section-bg {
  margin: auto;
  width: 750px;
}

.site-content .top-section-bg .top {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  background-image: url(../images/section-bg@2x.png);
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
}

.site-content .top-section-bg .content {
  background-color: #fff;
  border-left: #cccccc solid 2px;
  border-right: #cccccc solid 2px;
  /* border-top: #cccccc solid 1px; */
  border-bottom: #cccccc solid 2px;
  -webkit-border-bottom-right-radius: 30px;
  -webkit-border-bottom-left-radius: 30px;
  -moz-border-radius-bottomright: 30px;
  -moz-border-radius-bottomleft: 30px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

.site-content .top-section-bg .tagline {
  position: relative;
  margin: 0;
  height: 250px;
}

.site-content .top-section-bg .tagline img {
  margin-top: 15%;
  width: 60%;
  position: absolute;
  bottom: 20%;
  right: 0;
}

.site-content .top-section-bg .text-muted {
  color: #97cdc7 !important;
  /* font-weight: bold;
  font-size: 2.5rem; */
  text-align: center;
  margin: 0;
  padding: 35px 5% 70px;
  /* margin-top: calc(15% + 20px); */
}

.site-content .micro-section-grey {
  background-color: #eeeeee;
}

.site-content .micro-section .h5, .site-content .micro-section-grey .h5, .site-content .page-section .h5 {
  padding-bottom: 20px;
}

.site-content .micro-section .h5 span, .site-content .micro-section-grey .h5 span, .site-content .page-section .h5 span {
  text-transform: uppercase;
  font-weight: bold;
  color: #663300 !important;
}

.site-content .micro-section .h5 span:first-child, .site-content .micro-section-grey .h5 span:first-child, .site-content .page-section .h5 span:first-child {
  color: #67a7a4 !important;
}

.item, .row {
  position: relative;
}

.item .img {
  width: 200px;
  height: 258px;
  margin: auto;
  padding: 5px;
  position: relative;
}

.item .img img {
  background-color: #67a7a4;
  border: #67a7a4 solid 2px;
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.item .details {
  width: 200px;
  margin: auto;
}

.item .details .category {
  color: #663300 !important;
  font-size: 1rem;
}

.item .details .type {
  color: #663300 !important;
  font-size: 1rem;
  font-weight: 700;
  font-style: italic;
}

.item .details .title {
  color: #97cdc7 !important;
  font-size: 1.2rem;
  font-weight: bold;
}

.item .details .price {
  color: #663300 !important;
  font-size: 1.5rem;
  font-weight: bold;
}

.item .reviewer {
  font-style: italic;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: left !important;

}

.item .review {
  padding-top: 1.0rem;
  text-align: left !important;
}

.item .review::before {
  content: '\201C';
}

.item .review::after {
  content: '\201D';
}

.swiper-button-prev, .swiper-button-next {
  color: #663300 !important;
  top: 150px !important;
}

.swiper-button-prev::after, .swiper-button-next::after {
  font-size: 1.75rem !important;
}

.swiper-button-disabled {
  color: #97cdc7 !important;
}

.float-img {
  /* width: 100%; */
}

.float-img img {
  padding-bottom: 20px;
  width: 100%;
}

.team .circle-avatar img {
  border: #67a7a4 solid 3px;
  border-radius: 50%;
  width: 200px;
  overflow: hidden;
  padding: 10px;
}

.team .name {
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #663300 !important;
  padding-top: 1.5rem;
}

.team .title {
  font-style: italic;
  color: #67a7a4;
}

.team p {
  text-align: left !important;
}

.text-alert {
  color: #67a7a4;
}

.event-list {
  border-left: #cccccc solid 1px;
}

.event-list .h6 {
  color: #663300;
  font-weight: bold;
  font-size: 1.1rem;
}

.event-list dt {
  color: #67a7a4;
}

#contact-form em {
  color: crimson;
  font-size: 1rem;
}

.alert {
  border-radius: 1.5rem;
}

.alert-primary {
  background-color: #98cdc7 !important;
  color: #415976;
  font-weight: bold;
}

.alert-primary a, a:hover, a:visited {
  color: #415976 !important;
  font-weight: bold;
}

@media (max-width: 1440px) {
  /* .site-nav-header {
    min-height: calc(425vh);
  } */
}

@media (max-width: 1024px) {
  /* .site-nav-header {
    min-height: calc(425vh);
  } */
}

@media (max-width: 768px) {
  /* .site-nav-header {
    min-height: calc(600vh);
  } */

  .current-section {
    display: block;
  }

  .parent-logo {
    display: none;
  }

  .parent-logo-small {
    display: block;
  }

  .site-content .alert-section {
    width: 100%;
  }

  .site-content .top-section {
    padding: 0;
    /* background-size: 100%; */
  }

  .site-content .top-section-bg {
    width: 100%;
    height: 557px;
  }

  .float-img {
    float: none;
    width: 100%;
  }

  .site-content .top-section, .site-content .micro-section, .site-content .micro-section-grey {
    padding-top: 100px;
    padding-bottom: 0;
  }


  #reviews, #events, #contact {
    padding-bottom: 0px;
  }

}

@media (max-width: 426px) {
  .site-content .top-section-bg .tagline {
    height: 150px;
  }

  /* .site-nav-header {
    min-height: calc(1200vh);
  } */
}